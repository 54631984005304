<template>
  <div class="flex flex-col">
    <div class="w-full last:flex flex-col items-start self-center  mt-8 bg-yellow p-4">
      <h2 class="mb-4 ml-32 text-2xl font-bold pl-6">P05</h2>
      <div class="px-52 flex flex-col justify-items-center">
        <div class="">
          <h2 class="font-bold text-xl">Buscar Usuario (Nombre o Email)</h2>
          <input type="text" class="w-5/12 py-2 px-4 rounded-lg my-4 bg-white" v-model="search">
        </div>
        <v-data-table
          :headers="headers"
          :items="challengesP05"
          :items-per-page="10"
          class="w-full"
          loading-text="Cargando retos"
          :search="search"
        >
          <template v-slot:[`item.name`]="{ value }" >
             <p class="uppercase">{{ value }}</p> 
          </template>

          <template v-slot:[`item.state`]="{ value }" >
             <p class="uppercase">{{ value }}</p> 
          </template>


          <template v-slot:[`item.uid`]="{ value }">
            <button 
              v-if="value!=''"
              class="bg-red-400 px-4 py-2 rounded-xl font-bold"
              @click="acceptChallenge(value)"
            >
            Aceptar Reto</button>
          </template>

        </v-data-table>
     </div>
    </div>
  
  <div v-if="confirm" class="absolute top-0 left-0 bg-black bg-opacity-60 w-full h-full flex justify-center">
    <div class="absolute top-10 right-10 bg-white py-2 px-4 rounded-full font-bold cursor-pointer"
        @click="closeModal()"
    >
      X
    </div>
    <div class="bg-white w-1/2 h-1/2 mt-44 rounded-3xl py-10 px-10">
        <p class="text-5xl font-bold">Confirmar Reto </p>
        <p class="text-2xl font-bold mt-10">Nombre: <span class="text-2xl font-light text-red-700 uppercase">{{user.name}}</span></p>
        <p class="text-2xl font-bold">Email: <span class="text-2xl font-light text-red-700">{{user.email}}</span></p>
        <p class="text-2xl font-bold">Compañía: <span class="text-base font-light text-red-700">{{user.companyName}}</span></p>
        <div class="w-full flex justify-end ">
          <button 
            class="text-xl font-bold bg-yellow px-4 py-2 rounded-xl"
            @click="confirmChallenge()"
          >
            Aceptar Reto
          </button>
        </div>
    </div>
  </div>
  <Loader :isLoading="loadingData" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "@/components/Loader";
export default {
  components: { Loader },
  data() {
    return {
      challengesP05: [],
      users: [],
      user: {},
      headers: [
        { text: "Nombre", value: "name" },
        { text: "email", value: "email" },
        { text: "Estado", value: "state" },
        { text: "Aceptar", value: "uid" },
      ],
      loadingData: true,
      confirm: false,
      userUid: '',
      search:  '',
    };
  },
    methods: {
    ...mapActions([
      "challenges/getDCMChallengesP05",
      "challenges/acceptChallengeP05",
      "user/getUsers",
    ]),

    acceptChallenge(value){
      this.confirm = true;
      this.userUid = value;
      const userFilter = this.users.filter(user => user.uid ===  this.userUid);
      this.user = userFilter[0]
    },

    closeModal(){
      this.confirm = false;
    },

    async confirmChallenge(){

      const userFilter = this.users.filter(user => user.uid ===  this.userUid);
      const user = userFilter[0]
      const obj = {
        challengeId: "p05",
        companyId: user.companyId,
        companyName: user.companyName,
        cretedAt: Date.now(),
        isCompleted: true,
        isReviewed: true,
        isValid: true,
        reviewedAt: Date.now(),
        role: user.role,
        uid: user.uid,
        year: "2022"
      }
      this.loadingData = true;
      this.confirm = false;
      await this["challenges/acceptChallengeP05"](obj)
      const index = this.challengesP05.findIndex( user => {
        return user.uid === this.userUid
      })

      this.challengesP05[index].uid = '';
      this.challengesP05[index].state= 'Aceptado';
      this.loadingData = false;
    }
    },
      async mounted() {

        const res = await this["challenges/getDCMChallengesP05"]();
        this.users = await this["user/getUsers"]()
        if (res.success) {
          let challenges = [];
          if(res.challengesP05){
            challenges = res.challengesP05
          }
          const finalChallenge = [];

          this.users.forEach(user => {
            let state = "Pendiente";
            let uidField = user.uid
            challenges.forEach(challenge =>{
              if(user.uid === challenge.uid){
                state = "Aceptado";
                uidField = ''
              }
            })
            
            const obj = {
                name: user.name,
                email: user.email,
                state: state,
                uid: uidField
              }

            finalChallenge.push(obj)
          });
          
          this.challengesP05 = finalChallenge

        }
        this.loadingData = false;
        this.isLoading = false;
  },
};
</script>